export const resources = {
  en: {
    translation: {
      Profiles: 'Profiles',
      Dashboard: 'Dashboard',
      Logistics: 'Logistics',
      Ecommerce: 'Ecommerce',
      Management: 'Management',
      'Analytics 1': 'Analytics 1',
      'Analytics 2': 'Analytics 2',
      'Finance 1': 'Finance 1',
      'Finance 2': 'Finance 2',
      Accounts: 'Accounts',
      'User & Contact': 'User & Contact',
      Invoice: 'Invoice',
      Users: 'Users',
      Sessions: 'Sessions',
      'Bounce Rate': 'Bounce Rate',
      'Session Duration': 'Session Duration',
      'Live Online User': 'Live Online User',
      'Page views': 'Page views',
      'Visits by Top Referral Source': 'Visits by Top Referral Source',
      'Session by browser': 'Session by browser',
      'View Details': 'View Details'
    }
  },
  es: {
    translation: {
      Dashboard: 'Panel',
      Profiles: 'Perfiles',
      Management: 'Gestión',
      Logistics: 'Logística',
      Ecommerce: 'Comercio electrónico',
      'Analytics 1': 'Analítica 1',
      'Analytics 2': 'Analítica 2',
      'Finance 1': 'Finanzas 1',
      'Finance 2': 'Finanzas 2',
      Accounts: 'Cuentas',
      'User & Contact': 'Usuario y Contacto',
      Invoice: 'Factura',
      Users: 'Usuarias',
      Sessions: 'Sesiones',
      'Bounce Rate': 'Porcentaje',
      'Session Duration': 'Duración de la sesión',
      'Live Online User': 'Usuario en línea en vivo',
      'Page views': 'Visitas a la página',
      'Visits by Top Referral Source': 'Visitas por principal fuente de referencia',
      'Session by browser': 'Sesión por navegador',
      'View Details': 'Ver detalles'
    }
  }
};