import { Fragment } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
// CUSTOM DEFINED HOOK
import useAuth from 'hooks/useAuth';
import useLocation from 'hooks/useLocation';
export default function GuestGuard({
  children
}) {
  const {
    state
  } = useLocation();
  const {
    isAuthenticated,
    isInitialized,
    user
  } = useAuth();
  // if (!user) return null; // Or a loader component if desired

  // console.log("GUEST_GUARD :> " , {isAuthenticated , isInitialized})
  if (isAuthenticated) {
    if (state?.from) return <Navigate to={state.from} />;
    return <Navigate to="/dashboard" />;
  }
  return <Fragment>{children || <Outlet />}</Fragment>;
}