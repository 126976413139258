import { Navigate } from 'react-router-dom';
// CUSTOM DEFINED HOOK
import useAuth from 'hooks/useAuth';
import useLocation from 'hooks/useLocation';

export default function AuthGuard({
  children
}) {
  const {
    pathname
  } = useLocation();
  const {
    isAuthenticated,
    isInitialized,
    user
  } = useAuth();
  // if (!user) return null; // Or a loader component if desired
  // console.log("AUTH_GUARD :> " , {isAuthenticated , isInitialized})
  if (isAuthenticated) return <>{children}</>;
  return <Navigate replace to="/login" state={{
    from: pathname
  }} />;
}