import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
// CUSTOM COMPONENTS
import { Paragraph } from 'components/typography';
import AvatarLoading from 'components/avatar-loading';
import FlexRowAlign from 'components/flexbox/FlexRowAlign';
import { getData } from 'api-handlers/apiFunctions';
import { useEffect, useState  } from 'react';
import { LocalPrintshopRounded } from '@mui/icons-material';


export default function UserAccount() {
  const [loading, setLoading] = useState(true); 
  const [userInfo, setUserInfo] = useState({});

  const fetchUserInfo = async () => {
    try {
      const result = await getData( process.env.REACT_APP_USER_INFO_ENDPOINT )
      if (result.success){ 
        // console.log(result.data)
        setUserInfo(result.data.user);
        localStorage.setItem("userInfo" , JSON.stringify(result.data.user))
      }
    } 
    catch (error) { console.log({error}) }
    finally{
      setLoading(false);
    }
  };



  useEffect(() => {
    fetchUserInfo()
    console.log("<<<<< User Info Fetched >>>>>")
  } , [])




  return <FlexRowAlign flexDirection="column" py={5}>
      <Badge badgeContent={userInfo?.role} color="primary">
        <AvatarLoading alt="user" percentage={60} src="/static/user/avatar.png" sx={{
        width: 50,
        height: 50
      }} />
      </Badge>

      <Box textAlign="center" pt={1.5} pb={3}>
        <Chip variant="outlined" label="Welcome Back" size="small" />

        <Paragraph fontSize={16} fontWeight={600} mt={2}>
          {userInfo?.username}
        </Paragraph>

        <Paragraph fontSize={13} fontWeight={500} color="text.secondary" mt={0.5}>
          {userInfo?.email}
        </Paragraph>
      </Box>

      {/* <Button>Upgrade to Pro</Button> */}
    </FlexRowAlign>;
}