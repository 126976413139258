import { lazy } from 'react';
import Loadable from './Loadable';
import { AuthGuard  } from 'components/auth';
import useSettings from 'hooks/useSettings';
import LayoutV1 from 'layouts/layout-1';
import LayoutV2 from 'layouts/layout-2';


// Dashboard HomePage
const DashboardPage = Loadable(lazy(() => import('pages/dashboard/dashboard-home')));
// // User & Roles Module
// const AddNewUser = Loadable(lazy(() => import('pages/dashboard/users/add-new-user')));
// const UpdateUser = Loadable(lazy(() => import('pages/dashboard/users/update-user')));
// const UserListView = Loadable(lazy(() => import('pages/dashboard/users/user-list-1')));
// const UserGridView = Loadable(lazy(() => import('pages/dashboard/users/user-grid-1')));
// const RolesListView = Loadable(lazy(() => import('pages/dashboard/roles/roles-list-1')));
// const RolesAddView = Loadable(lazy(() => import('pages/dashboard/roles/add-new-role')));
// const RolesUpdateView = Loadable(lazy(() => import('pages/dashboard/roles/update-role')));
// // Scraper & Jobs Module
// const ScrapersListView = Loadable(lazy(() => import('pages/dashboard/scrapers/scrapers-list-1')));
// const JobsListView = Loadable(lazy(() => import('pages/dashboard/jobs/jobs-list-1')));




const ActiveLayout = () => {
  const {
    settings
  } = useSettings();
  switch (settings.activeLayout) {
    case 'layout1':
      return <LayoutV1 />;
    case 'layout2':
      return <LayoutV2 />;
    default:
      return <LayoutV1 />;
  }
};
export const DashboardRoutes = [{
  path: 'dashboard',
  element: <AuthGuard>
        <ActiveLayout />
      </AuthGuard>,
  children: [
    {
      path: '',
      element: <DashboardPage />
    }, 
    // {
    //   path: 'add-user',
    //   element: <AddNewUser />
    // }, 
    // {
    //   path : "update-user",
    //   element : <UpdateUser />
    // },
    // {
    //   path: 'users',
    //   element: <UserListView />
    // }, 
    // {
    //   path: 'users/view/grid',
    //   element: <UserGridView />
    // }, 
    // {
    //   path : "roles",
    //   element : <RolesListView />
    // },
    // {
    //   path : "add-role",
    //   element : <RolesAddView />
    // },
    // {
    //   path : "update-role",
    //   element : <RolesUpdateView />
    // },

    // {
    //   path : "scrapers",
    //   element : <ScrapersListView />
    // },
    // {
    //   path : "scraper/:scraperName" ,
    //   element : <JobsListView />
    // }
  ]
}];

